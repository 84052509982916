.leaguesT {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.leagueContainer {
  // padding: 10px;
  width: 250px;
  background-color: #F4EBDC;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .leagueLeft {
    width: 10px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px 0px 0px 10px;
    background: #E1B166;
  }

  .leagueGraphic {
    height: 50%;
    width: 20%;
  }

  .leagueRight {
    width: 23px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 0px 10px 10px 0px;
    background: #E1B166;
    align-self: flex-end;
    justify-self: flex-end;
  }
}


.createLeagues {
  background-color: #F4EBDC;
  width: 200px;
  border-radius: 5px;
  height: 50px;
  border: none;
  cursor: pointer;

}

.leaguePageContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .leagueContentContainer {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 75%;

    .titleContainer {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .title {
        font-weight: bold;
        font-size: 25px;
      }

    }
  }
}

.tournamentDetailContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profileTournaments {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}