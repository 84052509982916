@import 'https://fonts.googleapis.com/css?family=Roboto+Slab:400,700';

$color-theme: #488bf4;
$color-heading: #363636;
$color-content: #858585;
$color-background: #f0f2f2;
$color-footer: black;
$color-news: #4549a8;
$color-community: #dc563f;
$color-release: #60c645;
$color-border: #c0c0c8;
$font-content: "Roboto Slab", serif;
$bracket-width: 14rem;
$bracket-height: 3rem;
$lightTan: #EDCC8B;
$tan: #E1B166;

// body,
// html {
//   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
// }


button {
  cursor: pointer;
}

.createLeague,
.editLeague {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .leagueCreateContainer,
  .leagueEditContainer {
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    // align-items: center;
    height: auto;


    .bracketFormContainer {
      margin-top: 10px;
      background-color: $tan;
      padding: 10px;
      border-radius: 5px;
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 5px;

      form {
        display: flex;
        flex-direction: column;
        gap: 5px;


        textarea {
          resize: vertical;
          height: 100px;
        }
      }

      .participantPanel {
        display: flex;
        flex-direction: column;

        .participantHeader {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          button {
            width: 30px;
          }
        }

        .participantList {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;

          .teamLabel {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }

    .bracketDisplay {
      flex: 5 0 0;
      // width: 100%;
      // width: 500px;
      // background-color: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      width: 100%;
      align-items: center;
    }

  }
}


.adminContainer {
  display: flex;
  flex-direction: column;
}


h1 {
  display: flex;
  justify-content: center;
}



.tournamentName {
  font-size: 24px;
  font-weight: bold;
}

.bracket {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  color: black;
  margin: 5px;
  width: 100%;

  align-self: center;

  .round {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 20%;

    .title {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: 450;
      border-radius: 5px;
      align-self: center;
      font-size: 1.25rem;
      background-color: #E1B166;
      width: 100%;
    }

    .matchups {
      position: relative;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      gap: 1px;
      height: 100%;


      .bracketGame,
      .finalBracketGame {
        font-size: medium;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        // margin-top: 10px;
        width: 100%;
        height: 100%;

        .playerContainer {
          display: flex;
          flex-direction: column;
          border-radius: 5px;
          border: white solid 2px;
          margin: 20px 0px 20px 0px;
          padding: 5px;
          background-color: white;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          cursor: pointer;
        }

      }

      .finalBracketGame {
        .winner {
          color: black;
          background-color: #1cac1c;
          ;
        }
      }

      .pending {
        visibility: hidden;

        ::after {
          visibility: hidden;
        }

      }

      .playerContainer {
        // background-color: #4549a8;

        &:hover {
          // background-color: white;
          border: #313131 solid 2px;

          // border-color: #;
        }
      }

      .player {
        padding: 0px;
        // width: 100%;

        .seed {
          font-weight: bold;
          text-align: end;
          display: inline-block;
          width: 20px;
          padding-right: 10px;
        }

        .teamName {
          // margin-left: 10px;
        }
      }

      .loser {
        color: #dc563f
      }

      .winner {
        color: #1cac1c;
        font-weight: bold;
      }


    }


    //  inspired by  https://jsfiddle.net/mndv5sqy/54/

    .bracketGame:nth-child(odd)::after,
    .bracketGame:nth-child(even)::after {
      content: "";
      width: 30px;
      left: 100%;
      position: absolute;
      top: 15%;
      bottom: 50%;
      background:
        linear-gradient(#9D9D9D, #9D9D9D) bottom left /50% 3px,
        linear-gradient(#9d9d9d, #9D9D9D) center /3px 100%,
        linear-gradient(#9D9D9D, #9D9D9D) top right /50% 3px;
      background-repeat: no-repeat;
    }

    .bracketGame:nth-child(odd)::after {
      top: 50%;
      bottom: 0%;
      transform: scaleX(-1);
    }

    .bracketGame:nth-child(even)::after {
      top: 0%;
      bottom: 50%;
    }
  }

  // Additional styling for quarterfinals, semifinals, finals, champion...
}


.reportHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 10px;
}

.scoreReport {
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 5px;
  border: 2px solid #313131;
  background-color: $tan;

  .playerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;


    .leftHeader {
      width: 150px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 5px;

      .seed {
        font-weight: bold;
        text-align: end;
        display: inline-block;
        width: 20px;
        padding-right: 10px;
      }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    .rightHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      margin-right: 15px;
      // margin-right: 12px;

      .check {
        cursor: pointer;
      }

      .winner {
        color: #aced8b;
      }

      .scoreBar {
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // width: 50px;
        // height: 50px;
        border: $lightTan 2px solid;
        border-radius: 5px;
        // margin: 100px;
        // padding: 1px;

        input {
          width: 25px;
          // height: calc(100% - 25px);
          border-radius: 5px;
          // height: calc(100% - 5px);
          border: none;
          outline: 0;
          color: black;
          text-align: center;
        }

        .counters {
          height: 100%;
          display: flex;
          flex-direction: column;
          background-color: $lightTan;

          .up {
            border-radius: 0px 5px 0px 0px;
          }

          .down {
            border-radius: 0px 0px 5px 0px;
          }

          button {
            // display: flex;
            // flex-direction: column;
            // border: none;
            background-color: $lightTan;
            width: 20px;
            height: 20px;
            // border-radius: 5px;
            // font-size: 36px;
            cursor: pointer;
            border: none;
            outline: 0;

            // border-radius: 10px;
            &:hover {
              background-color: $tan;





            }
          }
        }
      }
    }


  }

  // background-color: #1cac1c;
}


//  For bracketDetails page 

.adminSpan {
  font-size: 28px;
  font-weight: bold;
  background-color: $tan;
  padding: 5px;
  width: 100%;
  // border-radius: 5px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
}


.joinTournament {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color: $tan;
  padding: 15px;
  border-radius: 5px;

  span {
    font-size: 15px;

  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;

    input {
      border: none;
    }
  }
}

.tournamentButton {
  background-color: white;
  // border-radius: 5px;
  // border-color: black;
  border: none;
  padding: 10px;

}

.error,
.status {
  text-align: center;
  color: rgb(255, 0, 0)
}

.createModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: $tan 1px solid;

  span {
    background-color: $tan;
    width: 100%;
    text-align: center;
  }

  button {
    background-color: $tan;
    // border-radius: 5px;
    // border-color: black;
    margin: 10px;
    border: none;
    padding: 10px;
  }
}